import React, {
	useEffect, useState, Fragment, useCallback
} from "react";
import styled from "styled-components";
import useAuth from "hooks/useAuth";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import Page from "components/layout/Page";
import CreateEditUser from "pages/admin/createEditUser";
import OverlayModal from "components/dialogs/OverlayModal";
import Layout from "components/layout/Layout";
import IconButton from "components/buttons/IconButton";
import IconAndTextButton from "components/buttons/IconAndTextButton";
import useApi, { API_DELETE } from "hooks/useApi";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.5rem 0;
	align-items: flex-start;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);

	.iconAndName, .options, .email, .projects {
		display: flex;
		align-items: center;
	}

	.options {
		flex-shrink: 0;
		min-width: 25%;
		padding-left: 0.5rem;
		justify-content: flex-end;
	}

	.iconAndName {
		min-width: 25%;
	}

	.name {
		color: var(--headline-dark);
		font-weight: bold;
		margin-left: 0.5rem;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.email {
		flex-shrink: 0;
		min-width: 25%;
		padding-left: 0.5rem;
	}

	.projects {
		flex-shrink: 0;
		min-width: 25%;
		padding-left: 0.5rem;
	}
`;

const ListUsers = () => {
	const { isLoggedIn } = useAuth();
	const { presentDialog, presentModal, dismissModal } = useDialog();
	const [userList, setUserList] = useState();
	const api = useApi();

	const getUsers = useCallback(() => {
		api({ path: "/users" }).then((data) => {
			setUserList(data);
		}).catch((e) => {
			presentDialog(infoDialog("Error", `Could not get user list ${e}`));
		});
	}, [api, presentDialog]);

	useEffect(() => {
		if (isLoggedIn && !userList) {
			getUsers();
		}
	}, [getUsers, isLoggedIn, userList]);

	const deleteUser = (email) => {
		presentDialog(infoDialog("Delete User", `Are you sure you want to delete user ${email}?`, () => {
			api({ method: API_DELETE, path: "/users", data: { email } }).then((data) => {
				setUserList(data);
			}).catch((err) => {
				presentDialog(infoDialog("Error", `Could not delete user ${err.message}`));
				getUsers();
			});
		}, undefined, { ok: "Yes", cancel: "No" }));
	};

	const completeNewEditUser = () => {
		getUsers();
		dismissModal();
	};

	const editUser = (user) => {
		presentModal(
			<OverlayModal
				rightButtons={[<button type="button" onClick={dismissModal}>Cancel</button>]}
				title={`${user ? "Edit" : "Create"} User`}
			>
				<CreateEditUser user={user} inModal onComplete={completeNewEditUser} />
			</OverlayModal>
		);
	};

	const listing = userList?.map((user) => (
		<StyledComponent key={user.email}>
			<div className="iconAndName">
				<div className="name">{`${user.title} ${user.firstName} ${user.lastName}`}</div>
			</div>
			<div className="email"><a href={`mailto:${user.email}`}>{ user.email }</a></div>
			<div className="projects">
				{
					user.role === "admin"
						? <b>Admin</b>
						: user.projects.map((pr) => (
							<>
								{pr}
								<br />
							</>
						))
				}
			</div>
			<div className="options">
				<IconButton
					label="Edit User"
					onClick={() => editUser(user)}
					icon="ant-design:edit-outlined"
				/>
				<IconButton
					onClick={() => deleteUser(user.email)}
					label="Delete User"
					icon="ant-design:delete-outlined"
				/>
			</div>
		</StyledComponent>
	));

	return (
		<>
			<Layout>
				<Page
					minHeight="75vh"
					middle={(
						<div style={{ marginTop: "5rem" }}>
							<h3>Users</h3>
							<p>
								{ listing }
							</p>
							<p>
								<IconAndTextButton
									text="Add User"
									onClick={() => editUser()}
									icon="ant-design:plus-square-outlined"
								/>
							</p>
						</div>
					)}
				/>
			</Layout>
		</>
	);
};

export default ListUsers;
